/* Importing Select SCSS file. */

/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "ngx-lightbox/lightbox.css";
@import 'ngx-toastr/toastr';

datatable-body-cell {
    display: flex !important;
    align-items: center !important;
}

hr {
    border-color: #E2E8F0;
}
