.app-toastr {
	width: 600px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
	padding-left: 0 !important;

	.toast-message {
		padding-left: 1.5rem;
		padding-top: 0.2rem;
	}

	.toast-close-button {
		padding-right: 0.5rem;
		font-weight: lighter;
		color: #8e92b9;
		font-size: 30px;
		font-weight: 100;
	}
}

.toast-content {
	content: "";
	display: block;
	width: 100%;
	height: 4px;
	top: 0;
	position: absolute;
}

.toastr-alert {
	background: #ffebef !important;

	&::after {
		@extend .toast-content;
		background: #a80027;
	}

	&.toast-error {
		color: #a80027 !important;
	}
}

.toastr-success {
	background: #EBFFEF!important;
	color: #249782!important;

	&::after {
		@extend .toast-content;
		background: #249782;
	}
}

.toastr-warning {
	background: #FFFAD1!important;
	color: rgba(99, 96, 6, 0.884)!important;

	&::after {
		@extend .toast-content;
		background:rgba(199, 193, 10, 0.884);
	}
}
