/*
Template Name: Tailwick - Admin & Dashboard Template
Author: Themesdesign
Version: 1.0.0
Website: https://themesdesign.in/
Contact: Themesdesign@gmail.com
File: tailwind scss File
*/

@import "fonts/fonts";
@import "buttons/button";
@import "common/sizing";

/* // Tailwindcss */
@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";
/* Custom Css */

@import "plugins/gridjs";
@import "plugins/select";
@import "plugins/slider";
@import "plugins/dropzon";
@import "plugins/others";

@import "common/ng-select";
@import "common/common";
@import "common/toastr";

.group\/menu-link:hover,
.animate {
   animation-iteration-count: 2;
   stroke-dasharray: 10;
}
