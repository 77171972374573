ol {
    list-style: decimal;
    margin: 0 1rem;
}

.ul-list ul {
    list-style-type: disc;
    padding-left: 1.5rem;

    & li {
        padding: 4px;
    }
}
