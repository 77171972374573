.ng-select.default .ng-select-container {
    min-height: 39px;
	border-radius: 0.375rem;

    .ng-value {
		padding: 2px 6px;
        flex-direction: row-reverse;
        display: flex;
		align-items: center;
		justify-content: flex-end;
		// overflow: visible;

		&:focus-within {
			outline: none;
		}
    }

	&.ng-select-focused {
		outline: none;
	}

	.ng-value-label {
		font-weight: 200 !important;
	}

	.ng-value .ng-value-icon:hover {
		background: #fee2e2!important;
	}

	.ng-value-container .ng-input>input {
		padding-top: 2px;
		padding-bottom: 2px;
	}
}

.ng-select .ng-value {
	.ng-value-label {
		color: #000000!important;
	}
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {

	.ng-value .ng-value-icon:hover {
		background: #fee2e2!important;
	}

	.ng-placeholder {
		top: 8px;
	}

	.ng-value-icon.left {
		width: 12px;
		height: 12px;
		border-radius: 100%;
		background: #fee2e2;
		text-align: center;
		display: flex!important;
		align-items: center;
		justify-content: center;
		border: none!important;
		color: #F0433D;
		font-size: 12px;
	}
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
	border: 1px solid rgb(226 232 240 / 1);
    box-shadow: none;
}

